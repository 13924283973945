<template>
	<main class="container">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Umsitarar</h1>

				<p class="color-grey">Yvirlit yvir brúkarar í skipanini</p>
			</div>

			<div>
				<router-link class="btn-add" :to="{ name: 'UserCreate' }"></router-link>
			</div>
		</div>

		<div v-if="loading" class="flex justify-center">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<table v-if="!loading">
			<thead>
				<th>#</th>
				<th>Navn</th>
				<th>Email</th>
				<th>&nbsp;</th>
			</thead>

			<tbody>
				<tr v-for="(user, index) in users" :key="`user-${index}`">
					<td>{{ user.id }}</td>
					<td>{{ user.firstName + ' ' + user.lastName }}</td>
					<td>{{ user.email }}</td>
					<td class="right">
						<i class="far fa-edit cursor-pointer" @click="editUser(user.id)"></i>
					</td>
				</tr>
			</tbody>
		</table>
	</main>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Users',

	components: {},

	data() {
		return {
			loading: true,
			users: [],
		};
	},

	mounted() {
		axios
			.get('/users')
			.then((response) => {
				this.users = response.data;
			})
			.catch((error) => {
				console.log('error fetching users', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		editUser(userId) {
			this.$router.push({ name: 'UserEdit', params: { id: userId } });
		},
	},
};
</script>
